<template>
  <div id="page-post">
    <div class="xs-p-20 xs-fill-white">
      <div class="lg-container content-box" v-html="content"></div>
    </div>
    <home-footer></home-footer>
  </div>
</template>
<script>
export default {
  components: {},
  mounted() {
    this.$root.$emit('onChangeMetaTitle', this.$t(this.$route.meta.key_title))
    this.initData()
  },
  data() {
    return {
      content: ''
    }
  },
  methods: {
    // initData() {
    //   var vm = this
    //   this.axios.apiContent.tnc(
    //     function(response) {
    //       vm.content = response.data.data.item.content
    //     },
    //     function(error) {}
    //   )
    // }
    initData() {
    var vm = this
    this.axios.apiContent.getPost(
      '/delivery_policy',
      function(response) {
        if (
          response &&
          response.data &&
          response.data.data &&
          response.data.data.item
        ) {
          vm.content = response.data.data.item.content
        }
      },
      function(error) {}
    )
  },
  }
}
</script>
<style scoped>
li.policy_item {
  list-style: none;
}

.main-content--privacy-policy .policy_content .policy_list .policy_item {
  padding: 5px 0px;
}

li.policy_item strong {
  display: block;
  font-weight: normal;
  padding: 30px 0 10px;
}

li.policy_item ul {
  list-style: decimal;
}

p {
  font-size: 15px;
}
h1 {
  font-size: 24px;
}
</style>
